<template>
    <div class="form">
        <h1>Заявление об открытии лицевого счета</h1>
        <div class="form__body">
            <div>
                <h3>
                    в Реестре владельцев инвестиционных паев
                    паевого фонда {{fundName}}
                    под управлением ЗАО УК «РВМ Капитал»
                </h3>
            </div>
            <client-block :mname="user.userName.mname" :name="user.userName.name" :sname="user.userName.sname"/>
            <passport-block :department="user.passport.department" :issue="user.passport.issue"
                            :issue-date="user.passport.issueDate"
                            :number="user.passport.number"/>

            <div class="row">
                <div class="col-12">
                    <h3>Прошу открыть лицевой счет вида:</h3>
                </div>
                <div class="col-12" v-for="el in [...this.checkboxes].slice(0, -1)" :key="el.id">
                    <rwm-checkbox
                            :label="el.label"
                            :checked="el.checked"
                            :error="errors.account"
                            @change="update(el.id, ...arguments)"
                            disabled
                            @click="showInfo()" />
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h3>Уведомление об операции/об отказе в проведении операции прошу направить:</h3>
                </div>
                <div class="col-12">
                    <rwm-checkbox
                            :label="checkboxes[checkboxes.length-1].label"
                            checked
                            :error="errors[checkboxes[checkboxes.length-1].id]"
                            @change="update(checkboxes[checkboxes.length-1].id, ...arguments)"
                            disabled
                    />
                </div>
            </div>
<!--            <div class="row" v-if="specreg && specreg.id !==2">-->
<!--                <div class="col-12">-->
<!--                    <p class="anketa">-->
<!--                        Настоящее согласие на обработку персональных данных предполагает передачу Регистратором мне всей информации, предусмотренной формой уведомления об операции (Приложение 32 к Правилам ведения реестра), способом, указанным мной в анкете зарегистрированного лица.-->
<!--                    </p>-->
<!--                    <p class="anketa">-->
<!--                        Настоящее  согласие на обработку персональных данных  действует  на период до истечения сроков хранения соответствующей информации или документов, содержащих указанную информацию, определяемых в соответствии с законодательством Российской Федерации, и может быть отозвано мной путем предоставления заявления в письменной форме.-->
<!--                    </p>-->
<!--                    <p class="anketa">-->
<!--                        Подтверждаю, что ознакомлен с целями обработки моих персональных данных, а именно с тем, что обработка персональных данных будет осуществляться Регистратором в моих интересах, в том числе для надлежащего исполнения обязанностей по ведению реестра владельцев инвестиционных паев.-->
<!--                    </p>-->
<!--                </div>-->
<!--            </div>-->
            <div class="row">
                <div class="col-3">
                    <rwm-button width="100%" @click="submit" :disabled="!readyToSubscribe">Подписать</rwm-button>
                </div>
            </div>
            <rwm-sms-checking v-model="_timer" :isShow="showChecking" :time="60" @sendAgain="sendAgain()"
                              @verified="codeIsVerified"/>
        </div>
    </div>
</template>

<script>
    import ClientBlock from "./blocks/ClientBlock";
    import PassportBlock from "./blocks/PassportBlock";
    import RwmCheckbox from "../../../../RWMCheckbox/RwmCheckbox";
    import RwmButton from "../../../../RWMButton/RwmButton";
    import RwmSmsChecking from "../../../../RWMSMSChecking/RwmSmsChecking";

    export default {
        name: "OpenPersonalAccount",
        components: {RwmSmsChecking, RwmButton, RwmCheckbox, PassportBlock, ClientBlock},
        props: {
            //specreg: [String, Number],
            user: Object,
            timer: {
                type: Boolean,
                default: false
            },
            showChecking: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            },
            fundName: String,
            ownerAccount: Boolean,
            managerAccount: Boolean,
            notaryAccount: Boolean,
            toManagementCompany: Boolean
        },
        computed: {
            _timer: {
                get() {
                    return this.timer
                },
                set(val) {
                    this.$emit('timer', val)
                }
            },
        },
        data() {
            return {
                errors: {
                    account: false,
                    toManagementCompany: false
                },
                readyToSubscribe: true,
                checkboxes: [
                    {
                        label: "Лицевой счет владельца",
                        id: "ownerAccount",
                        checked: true
                    },
                    {
                        label: "Лицевой счет доверительного управляющего",
                        id: "managerAccount",
                        checked: false
                    },
                    {
                        label: "Лицевой депозитный счет нотариуса",
                        id: "notaryAccount",
                        checked: false
                    },
                    {
                        label: "В Управляющую компанию",
                        id: "toManagementCompany",
                        checked: true
                    },
                ]
            }
        },
        methods: {
            showInfo() {
                this.$toast('В личном кабинете можно открыть только Лицевой счет владельца')
            },
            update(property, value) {
                this.$emit('update', {property: property, value: value})
            },
            sendAgain() {
                this.$emit('sendAgain')
            },
            codeIsVerified(code) {
                this.$emit('verified', code)
            },
            validate() {
                // if (this.ownerAccount || this.managerAccount || this.notaryAccount) {
                //     this.errors.account = false
                // } else this.errors.account = true
                // this.errors.toManagementCompany = !this.toManagementCompany
                let result = true
                for (let error in this.errors) {
                    if (this.errors[error]) {
                        error === "toManagementCompany" ? this.$toast.error('Направьте уведомление в управляющую компанию') : this.$toast.error('Выберите хотябы один из предложенных лицевых счетов')
                        result = false
                        break
                    }
                }
                return result
            },
            submit() {
                if (this.validate()) {
                    this.$emit('submit')
                }
            },
        }
    }
</script>

<style scoped lang="scss">
    .form {
        text-align: left;

        & h1 {
            margin-bottom: 32px;
        }

        &__body {
            display: flex;
            flex-direction: column;
            row-gap: 32px;

            & .block {
                display: flex;
                flex-direction: column;
                row-gap: 24px;

                &:after {
                    content: "";
                    opacity: 0.5;
                    border: 1px solid $RWM-grey-light;
                }

                &:last-child {
                    &:after {
                        border: none;
                    }
                }
            }
        }
    }
</style>
