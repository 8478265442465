<template>
  <open-personal-account
    :user="getUSER"
    :timer="timer"
    :showChecking="showChecking"
    :fund-name="draft ? draft.name : null"
    :disabled="timer"
    @submit="onSibmit"
    @sendAgain="onSibmit"
    @verified="onVerifiedCode"
    @update="onUpdate"
    @timer="timerIsOver"
  />
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import OpenPersonalAccount from "../../../../components/pages/cabinet/documents/forms/OpenPersonalAccount";
import moment from "moment";
import RWMSendingDocumentsMessageMixin from "../../../../components/mixins/RWMSendingDocumentsMessageMixin";

const model = {
  fond_id: null, //ID фонда
  owner_account: null,
  manager_account: null,
  notary_account: null,
  to_management_company: true,
};

export default {
  name: "FormOpenPersonalAccount",
  components: { OpenPersonalAccount },
  mixins: [RWMSendingDocumentsMessageMixin],
  computed: {
    ...mapGetters("errorModule", ["getERROR"]),
    ...mapGetters("AuthModule", ["getUSER"]),
    ...mapGetters("DocumentsModule", ["getDRAFT"]),
    draft() {
      return this.getDRAFT?.fond;
    },
  },
  data() {
    return {
      loading: false,
      timer: false,
      showChecking: false,
    };
  },
  methods: {
    ...mapActions("smsModule", ["REQUEST_SMS", "CONFIRM_SMS"]),
    ...mapActions("DocumentsModule", [
      "CREATE_DOCUMENT",
      "UPDATE_DOCUMENT",
      "GET_DOCUMENTS",
    ]),
    createDocument() {
      // looking fot exist document & if its not, creating new one
      let fundId = this.$route.query.to;
      if (!fundId) {
        this.$router.push("/cabinet/funds");
      }
      this.GET_DOCUMENTS({
        alias: "appl-open_pers_account",
        fundId: fundId,
      }).then((resp) => {
        if (resp) {
          if (!this.getDRAFT) {
            model.fond_id = fundId;
            this.CREATE_DOCUMENT({
              alias: "appl-open_pers_account",
              data: model,
            }).then((response) =>
              !response
                ? this.$toast.error(`${this.getERROR}`)
                : this.GET_DOCUMENTS({
                    alias: "appl-open_pers_account",
                    fundId: fundId,
                  })
            );
          }
        }
      });
    },
    onUpdate(value) {
      // let mode = {
      //     reason: "types_of_services",
      //     sendingPersonally: "sending_notification_personally",
      //     sendingEmail: "sending_notification_email",
      //     sendingFax: "sending_notification_fax",
      //     sendingRegisteredMail: "sending_notification_registered_mail",
      // }
      let data = {};
      data[value.property] = value.value;
      this.UPDATE_DOCUMENT({ id: this.getDRAFT.id, data: data });
      this.showChecking = false;
    },
    onSibmit() {
      this.loading = true;
      this.REQUEST_SMS({ phone: this.getUSER.email, doc_id: this.getDRAFT ? this.getDRAFT.id : null, action: 'document_sign' }).then((success) => {
        if (success) {
          this.showChecking = true;
          this.timer = true;
          this.loading = false;
        } else {
          this.$toast.error(`${this.getERROR}`);
        }
      });
    },
    onVerifiedCode(code) {
      this.CONFIRM_SMS({ phone: this.getUSER.email, key: code }).then(
        (success) => {
          if (success) {
            let data = { ...this.getDRAFT.data };
            data.date_sign = moment(new Date()).format("X");
            data.signed = true;

            this.UPDATE_DOCUMENT({ id: this.getDRAFT.id, data: data });

            this.show_sending_documents_message();
            setTimeout(() => {
              if (typeof this.$route.query.next !== "undefined" && this.$route.query.next !== "")
                this.$router.push("/cabinet/investment/purchase-of-investment-shares/" + this.$route.query.next + "?next")
              else {
                let id = this.$route.query.to;
                this.$router.push(`/cabinet/investment/purchase-of-investment-shares/${id}`);
              }
            }, 1000);
          } else {
            this.$toast.error(`${this.getERROR}`);
          }
        }
      );
    },
    timerIsOver(timer) {
      this.timer = timer;
    },
  },
  mounted() {
    this.createDocument();
  },
};
</script>
